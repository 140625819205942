// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Li_li_154cg{margin-top:18px;padding-left:20px;position:relative;text-align:left}.Li_li_154cg:before{background-color:#000;color:#000;content:\"-\";left:0;position:absolute}", ""]);
// Exports
exports.locals = {
	"li": "Li_li_154cg"
};
module.exports = exports;
