<script>
export default {
  name: 'RichTextNode',
  props: {
    data: Object,
  },
  render(createElement) {
    if (this.data.type === 'hyperlink') {
      return createElement('a', {
          attrs: {
            href: this.data.data.url,
            target: '_blank',
            rel: 'nofollow',
          },
        }, this.data.text)
    }

    if (this.data.type === 'strong') {
      return createElement('strong', null, this.data.text)
    }

    return this._v(this.data.text)
  },
}
</script>
