var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Section',{class:_vm.$style.footer,attrs:{"type":"footer","compact":""}},[_c('Heading',{class:_vm.$style.heading},[_vm._v("Contact us")]),_c('RichText',{class:_vm.$style.text,attrs:{"data":_vm.contact.text}}),(typeof _vm.officeStatus === 'string')?_c('p',{class:_vm.$style.p},[_vm._v("Office is currently "),_c('span',{class:_vm.officeStatusClass},[_vm._v(_vm._s(_vm.officeStatus))])]):_vm._e(),_c('div',{class:_vm.$style.lists},[(_vm.contact.phoneNumbers.length)?_c('ul',{class:_vm.$style.list},_vm._l((_vm.contact.phoneNumbers),function(ref,key){
var label = ref.label;
var numbers = ref.numbers;
return _c('li',{key:key,class:_vm.$style.li},[_c('span',{class:[_vm.$style.block, _vm.$style.label]},[_vm._v(_vm._s(label))]),_c('span',{class:_vm.$style.values},_vm._l((numbers),function(ref,j){
var href = ref.href;
var label = ref.label;
return _c('a',{key:j,class:[_vm.$style.block, _vm.$style.anchor],attrs:{"href":href,"title":href}},[_vm._v(_vm._s(label))])}),0)])}),0):_vm._e(),_c('NoSSR',[(_vm.contact.email)?_c('ul',{class:_vm.$style.list},[_c('li',{class:_vm.$style.li},[_c('span',{class:[_vm.$style.block, _vm.$style.label]},[_vm._v("Email")]),_c('a',{class:[_vm.$style.block, _vm.$style.anchor],attrs:{"href":("mailto:" + (_vm.contact.email))}},[_vm._v(_vm._s(_vm.contact.email))])])]):_vm._e()]),(_vm.contact.socialMedia.length)?_c('ul',{class:_vm.$style.list},[_c('li',{class:_vm.$style.li},[_c('span',{class:[_vm.$style.block, _vm.$style.label]},[_vm._v("Social media")]),_c('span',{class:_vm.$style.values},_vm._l((_vm.contact.socialMedia),function(ref,key){
var label = ref.label;
var link = ref.link;
return _c('a',{key:key,class:[_vm.$style.block, _vm.$style.anchor],attrs:{"href":link.url,"title":link.url,"target":"_blank","rel":"nofollow"}},[_vm._v(_vm._s(label))])}),0)])]):_vm._e()],1),_c('small',{class:_vm.$style.copyright},[_vm._v("© "+_vm._s(_vm.year)+" LCI Corporation")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }