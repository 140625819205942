<template>
  <Section :class="$style.facilities" compact>
    <Heading :class="$style.heading">Facilities</Heading>

    <RichText :class="$style.text" :data="text_before"/>
    
    <Ul :class="$style.list">
      <Li :class="$style.li" v-for="({ label }, key) in list" :key="key">{{ label }}</li>
    </Ul>
  </Section>
</template>

<script>
import Heading from '@/components/Heading.vue'
import Li from '@/components/Li.vue'
import RichText from '@/components/RichText.vue'
import Section from '@/components/Section.vue'
import Ul from '@/components/Ul.vue'

export default {
  name: 'Facilities',
  components: {
    Heading,
    Li,
    RichText,
    Section,
    Ul,
  },
  props: {
    data: Object,
  },
  computed: {
    text_before() {
      return this.data.text_before
    },
    list() {
      return this.data.list
    },
  }
}
</script>

<style module lang="scss">
.facilities {
  background-color: #eae3e0;
}

.heading {
  color: #5a463e;
}

.text {
  color: #5a463e;
  font-size: 1.8em;
}

.list {
  column-gap: 40px;

  @media (min-width: 426px) {
    column-count: 2;
  }

  @media (min-width: 1025px) {
    column-count: 3;
  }
}

.li {
  color: #5a463e;
  font-size: 1.8em;
}

.li:first-child {
  margin-top: 0;
}

.li::before {
  background-color: #d5c7c2 !important; // @TODO
  color: #d5c7c2 !important; // @TODO
}
</style>
