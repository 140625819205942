<template>
  <ul :class="$style.ul">
    <slot/>
  </ul>
</template>

<script>
export default {
  name: 'Ul',
}
</script>

<style module lang="scss">
.ul {
  list-style: none;
  margin-bottom: 27px;
  margin-top: 27px;
  padding-left: 0;
}
</style>
