// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Documents_documents_2yJKe{background-color:#efefef}.Documents_text_1-8nX{font-size:1.8em}.Documents_list_oz5-9{display:flex;flex-wrap:wrap;justify-content:center;list-style:none;margin-bottom:0;margin-top:0;padding-left:0}.Documents_li_2h0BP{margin-left:1px;margin-top:1px}.Documents_anchor_lshDv{align-items:center;background-color:#fff;color:#000;display:flex;font-size:1.6em;padding:18px 20px;text-decoration:none}.Documents_anchor_lshDv:hover{box-shadow:0 0 18px rgba(0,0,0,.05);position:relative;z-index:1}.Documents_icon_29JdL{height:28px;opacity:.75;transition:opacity .25s}.Documents_anchor_lshDv:hover .Documents_icon_29JdL{opacity:1}.Documents_label_dtb48{margin-left:10px;opacity:.75;text-align:left;transition:opacity .25s}.Documents_anchor_lshDv:hover .Documents_label_dtb48{opacity:1}", ""]);
// Exports
exports.locals = {
	"documents": "Documents_documents_2yJKe",
	"text": "Documents_text_1-8nX",
	"list": "Documents_list_oz5-9",
	"li": "Documents_li_2h0BP",
	"anchor": "Documents_anchor_lshDv",
	"icon": "Documents_icon_29JdL",
	"label": "Documents_label_dtb48"
};
module.exports = exports;
