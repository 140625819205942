// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MaxWidth_max-width_3QGa7{margin-left:auto;margin-right:auto;max-width:1200px;width:100%}", ""]);
// Exports
exports.locals = {
	"max-width": "MaxWidth_max-width_3QGa7"
};
module.exports = exports;
