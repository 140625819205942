<template>
  <li :class="$style.li">
    <slot/>
  </li>
</template>

<script>
export default {
  name: 'Li',
}
</script>

<style module lang="scss">
.li {
  margin-top: 18px;
  padding-left: 20px;
  position: relative;
  text-align: left;
}

.li::before {
  background-color: #000;
  color: #000;
  content: '-';
  left: 0;
  position: absolute;
}
</style>
