import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    description: null,
    httpStatus: null,
    imageColor: {},
    map: null,
    marker: null,
  },
  mutations: {
    setDescription(state, value) {
      state.description = value
    },
    setImageColor(state, value) {
      state.imageColor[value.key] = value.value
    },
    setMap(state, value) {
      state.map = value
    },
    setMarker(state, value) {
      state.marker = value
    },
  },
  actions: {
  },
  modules: {
  },
})
