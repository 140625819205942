<template>
  <div :class="$style['not-found']">
    <Heading :level="1" :class="$style.heading">404</Heading>
    <p :class="$style.text">The page you are looking for does not exist.</p>
    <router-link :class="$style.cta" to="/">Go home</router-link>
  </div>
</template>

<script>
// import LazyHydrate from 'vue-lazy-hydration'
import Heading from '@/components/Heading.vue'

export default {
  name: 'NotFound',
  components: {
    Heading,
    // LazyHydrate,
  },
  watch: {
    description(val) {
      this.$store.commit('setDescription', val)
    }
  },

  metaInfo() {
    return {
      meta: [{ name: 'description', content: '404' }]
    }
  },
}
</script>

<style module lang="scss">
.not-found {
  color: #fff;
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
}

.heading {
  font-size: 10em !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.text {
  color: #fff;
  font-size: 2em;
  margin-bottom: 0;
  margin-top: 0;
}

.cta {
  background-color: #e8e2e6;
  color: #554350;
  display: inline-block;
  font-size: 1.6em;
  font-weight: 500;
  margin-top: 27px;
  padding: 18px 30px;
  text-decoration: none;
  transition: background-color .25s;
}

.cta:hover {
  background-color: #fff;
}
</style>
