<template>
  <Section :class="$style.dorm" compact>
    <Heading>Dorm rates</Heading>

    <RichText :class="$style.text" :data="text_before"/>

    <ul :class="$style.rooms">
      <li :class="$style.room" v-for="({ bed, capacity, price_long_term, price_short_term, size }, i) in rooms" :key="i">
        <span :class="[$style.block, $style.capacity]">Room for {{ capacity }}</span>

        <ul :class="$style['room-details']">
          <li :class="$style['room-detail']">
            <span :class="[$style.block, $style.label]">Room size</span>
            <span :class="[$style.block, $style.value]">{{ size }}</span>
          </li>
        </ul>

        <ul :class="$style['room-details']">
          <li :class="$style['room-detail']">
            <span :class="[$style.block, $style.label]">Bed type</span>
            <span :class="[$style.block, $style.value]">{{ bed }}</span>
          </li>
        </ul>

        <ul :class="$style['room-details']">
          <li :class="$style['room-detail']">
            <span :class="[$style.block, $style.label]">long term</span>
        <span :class="[$style.block, $style.value]">{{ price_long_term }}</span>
          </li>
        </ul>

        <ul :class="$style['room-details']">
          <li :class="$style['room-detail']">
            <span :class="[$style.block, $style.label]">short term</span>
            <span :class="[$style.block, $style.value]">{{ price_short_term }}</span>
          </li>
        </ul>
      </li>
    </ul>

    <div :class="$style.lists">
      <div :class="[$style['list-container'], $style.payment]">
        <Heading :level="3" :class="$style['list-heading']">Payment</Heading>

        <Ul :class="$style.list">
          <Li :class="$style.li" v-for="({ label }, i) in payment" :key="i">{{ label }}</Li>
        </Ul>
      </div>

      <div :class="[$style['list-container'], $style.disclaimer]">
        <Heading :level="3" :class="$style['list-heading']">Disclaimer</Heading>

        <Ul :class="$style.list">
          <Li :class="$style.li" v-for="({ label }, i) in disclaimer" :key="i">{{ label }}</Li>
        </Ul>
      </div>

      <div :class="[$style['list-container'], $style['house-rules']]">
        <Heading :level="3" :class="$style['list-heading']">House rules</Heading>

        <Ul :class="$style.list">
          <Li :class="$style.li" v-for="({ label }, i) in house_rules" :key="i">{{ label }}</Li>
        </Ul>
      </div>
    </div>
  </Section>
</template>

<script>
import Heading from '@/components/Heading.vue'
import Li from '@/components/Li.vue'
import RichText from '@/components/RichText.vue'
import Section from '@/components/Section.vue'
import Ul from '@/components/Ul.vue'

export default {
  name: 'Rates',
  components: {
    Heading,
    Li,
    RichText,
    Section,
    Ul,
  },
  props: {
    data: Object,
  },
  computed: {
    text_before() {
      return this.data.text_before
    },
    rooms() {
      return this.data.rooms
    },
    payment() {
      return this.data.payment
    },
    disclaimer() {
      return this.data.disclaimer
    },
    house_rules() {
      return this.data.house_rules
    },
  }
}
</script>

<style module lang="scss">
.dorm {
  background-color: #fff;
}

.text {
  font-size: 1.8em;
}

.rooms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0 -20px;
  padding-left: 0;
}

.room {
  border-radius: 3px;
  margin: 18px 20px;
  overflow: hidden;
}

.block {
  padding: 6px 20px;

  @media (min-width: 769px) {
    padding: 9px 25px;
  }

  @media (min-width: 1025px) {
    padding: 12px 30px;
  }

  @media (min-width: 1441px) {
    padding: 15px 35px;
  }
}

.capacity {
  background-color: #d5c7c2;
  color: #5a463e;
  display: block;
  font-size: 1.8em;
  font-weight: 500;
}

.room-details {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.room-detail {
  display: flex;
  margin-top: 1px;
}

.label {
  background-color: #d5c7c2;
  color: #5a463e;
  font-size: 1.6em;
}

.value {
  background-color: #eae3e0;
  color: #5a463e;
  flex-grow: 1;
  font-size: 1.8em;
  margin-left: 1px;
  text-align: left;
}

.lists {
  @media (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px; 
  }

  @media (min-width: 1025px) {
    flex-wrap: nowrap;
  }
}

.list-container {
  @media (min-width: 769px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.list-heading {
  text-align: left;
}

.payment .list-heading {
  color: #8b560d;
}

.disclaimer .list-heading {
  color: #934005;
}

.house-rules .list-heading {
  color: #8b330d;
}

.list {
  margin-bottom: 27px;
  margin-top: 0;
}

.li {
  font-size: 1.8em;
}

.payment .li::before {
  background-color: #fae8d0;
  color: #fae8d0;
}

.disclaimer .li::before {
  background-color: #fde1cd;
  color: #fde1cd;
}

.house-rules .li::before {
  background-color: #faddd0;
  color: #faddd0;
}
</style>
