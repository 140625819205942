<template>
  <section :is="type" :class="sectionClass">
    <span :class="$style.padding" v-if="compact"/>
    
    <MaxWidth :class="$style['max-width']">
      <slot/>
    </MaxWidth>

    <span :class="$style.padding" v-if="compact"/>
  </section>
</template>

<script>
import MaxWidth from '@/components/MaxWidth.vue'

export default {
  name: 'Section',
  components: {
    MaxWidth,
  },
  props: {
    compact: Boolean,
    type: {
      type: String,
      default: 'section',
    },
  },
  computed: {
    sectionClass() {
      return [
        this.$style.section,
        {
          [this.$style['--compact']]: this.compact,
        },
      ]
    }
  },
}
</script>

<style module lang="scss">
.section {
  overflow: hidden;
  padding-left: 6.25%;
  padding-right: 6.25%;
}

.padding {
  display: block;
  max-height: 90px;
  overflow: hidden;
}

.padding::before {
  content: '';
  display: block;
  padding-top: 9.648%;
}

.max-width {
  padding-bottom: 9.648%;
  padding-top: 9.648%;
}

.--compact .max-width {
  padding-bottom: 0;
  padding-top: 0;
}
</style>
