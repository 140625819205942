// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dorm_dorm_3W24L{background-color:#fff}.Dorm_text_242rt{font-size:1.8em}.Dorm_rooms_uQGER{display:flex;flex-wrap:wrap;justify-content:center;list-style:none;margin:0 -20px;padding-left:0}.Dorm_room_lUIL_{border-radius:3px;margin:18px 20px;overflow:hidden}.Dorm_block_uI_Kv{padding:6px 20px}@media(min-width:769px){.Dorm_block_uI_Kv{padding:9px 25px}}@media(min-width:1025px){.Dorm_block_uI_Kv{padding:12px 30px}}@media(min-width:1441px){.Dorm_block_uI_Kv{padding:15px 35px}}.Dorm_capacity_2Q5gh{background-color:#d5c7c2;color:#5a463e;display:block;font-size:1.8em;font-weight:500}.Dorm_room-details_1ahIH{list-style:none;margin-bottom:0;margin-top:0;padding-left:0}.Dorm_room-detail_2m2dG{display:flex;margin-top:1px}.Dorm_label_3fPPy{background-color:#d5c7c2;color:#5a463e;font-size:1.6em}.Dorm_value_1hTL1{background-color:#eae3e0;color:#5a463e;flex-grow:1;font-size:1.8em;margin-left:1px;text-align:left}@media(min-width:769px){.Dorm_lists_39QjR{display:flex;flex-wrap:wrap;margin-left:-20px;margin-right:-20px}}@media(min-width:1025px){.Dorm_lists_39QjR{flex-wrap:nowrap}}@media(min-width:769px){.Dorm_list-container_11HXY{margin-left:20px;margin-right:20px}}.Dorm_list-heading_8LLcT{text-align:left}.Dorm_payment_1UKzt .Dorm_list-heading_8LLcT{color:#8b560d}.Dorm_disclaimer_aJ6Ln .Dorm_list-heading_8LLcT{color:#934005}.Dorm_house-rules__yth9 .Dorm_list-heading_8LLcT{color:#8b330d}.Dorm_list_P2Me1{margin-bottom:27px;margin-top:0}.Dorm_li_2HPl9{font-size:1.8em}.Dorm_payment_1UKzt .Dorm_li_2HPl9:before{background-color:#fae8d0;color:#fae8d0}.Dorm_disclaimer_aJ6Ln .Dorm_li_2HPl9:before{background-color:#fde1cd;color:#fde1cd}.Dorm_house-rules__yth9 .Dorm_li_2HPl9:before{background-color:#faddd0;color:#faddd0}", ""]);
// Exports
exports.locals = {
	"dorm": "Dorm_dorm_3W24L",
	"text": "Dorm_text_242rt",
	"rooms": "Dorm_rooms_uQGER",
	"room": "Dorm_room_lUIL_",
	"block": "Dorm_block_uI_Kv",
	"capacity": "Dorm_capacity_2Q5gh",
	"room-details": "Dorm_room-details_1ahIH",
	"room-detail": "Dorm_room-detail_2m2dG",
	"label": "Dorm_label_3fPPy",
	"value": "Dorm_value_1hTL1",
	"lists": "Dorm_lists_39QjR",
	"list-container": "Dorm_list-container_11HXY",
	"list-heading": "Dorm_list-heading_8LLcT",
	"payment": "Dorm_payment_1UKzt",
	"disclaimer": "Dorm_disclaimer_aJ6Ln",
	"house-rules": "Dorm_house-rules__yth9",
	"list": "Dorm_list_P2Me1",
	"li": "Dorm_li_2HPl9"
};
module.exports = exports;
