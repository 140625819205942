// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".About_about_cvOW6{background-color:#608c82}.About_text_3rrS-{color:#fff;font-size:2em}@media(min-width:769px){.About_text_3rrS-{-moz-column-count:2;column-count:2;-moz-column-gap:40px;column-gap:40px;text-align:left}}@media(min-width:1025px){.About_text_3rrS-{-moz-column-count:3;column-count:3}}@media(max-width:768px){.About_text--show-less_2yVlO>*+*{display:none}}@media(min-width:769px){.About_text_3rrS- p:first-child{margin-top:0}}.About_read-more_1MEf-{background-color:#e0eae8;color:#3e5a54;cursor:pointer;display:inline-block;font-size:1.6em;margin-bottom:27px;padding:6px 20px;transition:background-color .25s}@media(min-width:769px){.About_read-more_1MEf-{display:none}}.About_read-more_1MEf-:hover{background-color:#fff}", ""]);
// Exports
exports.locals = {
	"about": "About_about_cvOW6",
	"text": "About_text_3rrS-",
	"text--show-less": "About_text--show-less_2yVlO",
	"read-more": "About_read-more_1MEf-"
};
module.exports = exports;
