<template>
  <ul :class="$style.gallery">
    <li :class="$style.item" v-for="({ image }, key) in data" :key="key">
      <Img :class="$style.image" :data="image" :width="318" :height="318"/>
    </li>
  </ul>
</template>

<script>
import Img from '@/components/Img.vue'

export default {
  name: 'Gallery',
  components: {
    Img,
  },
  props: {
    data: Array
  },
}
</script>

<style module lang="scss">
.gallery {
  background: #fff;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  list-style: none;
  overflow: auto;
  overflow-y: hidden;
  margin: 0 auto;
  padding-left: 0;
  scroll-snap-type: x mandatory;
  white-space: nowrap;

  @media (min-width: 769px) {
    // white-space: normal;
  }
}

.item {
  scroll-snap-align: start;
  box-sizing: border-box;
  border-left: 1px solid white;
  display: inline-block;
  margin-top: 1px;

  @media (min-width: 769px) {
    // width: 100% * (1 / 3);
  }

  @media (min-width: 1025px) {
    // width: 100% * (1 / 4);
  }

  @media (min-width: 1441px) {
    // width: 100% * (1 / 5);
  }
}

.image {
  @media (min-width: 769px) {
    opacity: .5;
    transition: opacity .25s;
    // width: 100% !important;
  }
}

.item:hover .image {
  opacity: 1;
}
</style>
