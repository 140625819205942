// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OnSite_on-site_11vdx{background-color:#efefef}.OnSite_services-and-establishments_3qaX2{margin-bottom:27px;margin-top:27px}@media(min-width:1025px){.OnSite_services-and-establishments_3qaX2{display:flex;position:relative}}@media(min-width:1025px){.OnSite_services-and-establishments_3qaX2:before{background-color:#000;bottom:0;content:\"\";left:50%;position:absolute;top:0;width:1px}}.OnSite_services_3UXqf{list-style:none;margin-bottom:0;margin-top:0;padding-left:0}@media(min-width:1025px){.OnSite_services_3UXqf{flex-basis:50%;padding-right:40px}}@media(min-width:1025px){.OnSite_service_3RDLk{text-align:right}}.OnSite_text_2GWDA{font-size:1.8em}.OnSite_text_2GWDA a{background-color:#6a5464;color:#fff;display:inline-block;font-weight:500;padding-left:5px;padding-right:5px;text-decoration:none;transition:background-color .25s}.OnSite_text_2GWDA a:hover{background-color:#8e7086}.OnSite_establishments_EN1XS{display:flex;flex-direction:column;margin-bottom:0;margin-top:0}@media(min-width:426px){.OnSite_establishments_EN1XS{align-items:center}}@media(min-width:1025px){.OnSite_establishments_EN1XS{align-items:flex-start;flex-basis:50%;padding-left:40px}}.OnSite_establishment_aLOe1{font-size:1.8em}@media(min-width:1025px){.OnSite_establishment_aLOe1{margin-top:27px;white-space:nowrap}}.OnSite_establishment_aLOe1:before{background-color:#d1c6ce;color:#d1c6ce}", ""]);
// Exports
exports.locals = {
	"on-site": "OnSite_on-site_11vdx",
	"services-and-establishments": "OnSite_services-and-establishments_3qaX2",
	"services": "OnSite_services_3UXqf",
	"service": "OnSite_service_3RDLk",
	"text": "OnSite_text_2GWDA",
	"establishments": "OnSite_establishments_EN1XS",
	"establishment": "OnSite_establishment_aLOe1"
};
module.exports = exports;
