import Vue from 'vue'

import { sync } from 'vuex-router-sync'
import VueApollo from 'vue-apollo'
import { createApolloClient } from './apollo'

import App from './App-100roxasavenue.vue'
import router from './router/100roxasavenue'
import store from './store/100roxasavenue'

Vue.config.productionTip = false

Vue.use(VueApollo)

function createApp(context) {
  const apolloClient = createApolloClient(context.ssr)
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
  })

  sync(store, router)

  return {
    app: new Vue({
      router,
      store,
      render: h => h(App),
      apolloProvider
    }),
    router,
    store,
    apolloProvider
  }
}

export default createApp
