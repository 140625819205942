<template>
  <div>
    <RichTextElement v-for="(item, key) in data" :key="key" :data="item"/>
  </div>
</template>

<script>
import RichTextElement from '@/components/RichTextElement.vue'

export default {
  name: 'RichText',
  components: {
    RichTextElement,
  },
  props: {
    data: Array,
  },
}
</script>
