<template>
  <Section :class="$style['on-site']" compact>
    <Heading>Services &amp; establishments</Heading>

    <div :class="$style['services-and-establishments']">
      <ul :class="$style.services">
        <li :class="$style.service" v-for="({ image, text, title }, key) in services" :key="key">
          <Img :data="image" :height="54"/>
          <Heading :level="3">{{ title }}</Heading>
          <RichText :class="$style.text" :data="text"/>
        </li>
      </ul>

      <Ul :class="$style.establishments">
        <Li :class="$style.establishment" v-for="({ label }, key) in establishments" :key="key">{{ label }}</Li>
      </Ul>
    </div>
  </Section>
</template>

<script>
import Heading from '@/components/Heading.vue'
import Img from '@/components/Img.vue'
import Li from '@/components/Li.vue'
import RichText from '@/components/RichText.vue'
import Section from '@/components/Section.vue'
import Ul from '@/components/Ul.vue'

export default {
  name: 'OnSite',
  components: {
    Heading,
    Img,
    Li,
    RichText,
    Section,
    Ul,
  },
  props: {
    data: Object,
  },
  computed: {
    services() {
      return this.data.services
    },
    establishments() {
      return this.data.establishments
    }
  }
}
</script>

<style module lang="scss">
.on-site {
  background-color: #efefef;
}

.services-and-establishments {
  margin-bottom: 27px;
  margin-top: 27px;

  @media (min-width: 1025px) {
    display: flex;
    position: relative;
  }
}

.services-and-establishments::before {
  @media (min-width: 1025px) {
    background-color: #000;
    bottom: 0;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
    width: 1px;
  }
}

.services {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;

  @media (min-width: 1025px) {
    flex-basis: 50%;
    padding-right: 40px;
  }
}

.service {
  @media (min-width: 1025px) {
    text-align: right;
  }
}

.text {
  font-size: 1.8em;
}

.text a {
  background-color: #6a5464;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  transition: background-color .25s;
}

.text a:hover {
  background-color: #8e7086;
}

.establishments {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;

  @media (min-width: 426px) {
    align-items: center;
  }

  @media (min-width: 1025px) {
    align-items: flex-start;
  }

  @media (min-width: 1025px) {
    flex-basis: 50%;
    padding-left: 40px;
  }
}

.establishment {
  font-size: 1.8em;

  @media (min-width: 1025px) {
    margin-top: 27px;
    white-space: nowrap;
  }
}

.establishment::before {
  background-color: #d1c6ce;
  color: #d1c6ce;
}
</style>
