<template>
  <div>
    <LazyHydrate :on-interaction="['touchstart', 'mouseover']">
      <About :data="about"/>
    </LazyHydrate>
    <LazyHydrate when-visible>
      <Gallery :data="gallery"/>
    </LazyHydrate>
    <LazyHydrate never>
      <Facilities :data="facilities"/>
    </LazyHydrate>
    <LazyHydrate when-visible>
      <OnSite :data="onSite"/>
    </LazyHydrate>
    <LazyHydrate never>
      <Dorm :data="dorm"/>
    </LazyHydrate>
    <LazyHydrate never>
      <Documents :data="documents"/>
    </LazyHydrate>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import LazyHydrate from 'vue-lazy-hydration'
import About from '@/components/About.vue'
import Documents from '@/components/Documents.vue'
import Dorm from '@/components/Dorm.vue'
import Facilities from '@/components/Facilities.vue'
import Gallery from '@/components/Gallery.vue'
import OnSite from '@/components/OnSite.vue'

export default {
  name: 'Home',
  components: {
    About,
    Documents,
    Dorm,
    Facilities,
    Gallery,
    LazyHydrate,
    OnSite,
  },
  computed: {
    data () {
      return this.allHomepages?.edges[0].node
    },

    description() {
      return this.data?.description
    },

    about() {
      return this.data?.about
    },
    gallery() {
      return this.data?.gallery
    },
    facilities() {
      return {
        text_before: this.data?.facilities_text_before,
        list: this.data?.facilities,
      }
    },
    onSite() {
      return {
        services: this.data?.services,
        establishments: this.data?.establishments,
      }
    },
    dorm() {
      return {
        text_before: this.data?.dorm_text_before,
        rooms: this.data?.rooms,
        payment: this.data?.payment,
        disclaimer: this.data?.disclaimer,
        house_rules: this.data?.house_rules,
      }
    },
    documents() {
      return {
        text_before: this.data?.documents_text_before,
        documents: this.data?.documents,
        text_after: this.data?.documents_text_after,
      }
    },
  },
  watch: {
    description(val) {
      this.$store.commit('setDescription', val)
    }
  },

  metaInfo() {
    return {
      meta: [{ name: 'description', content: this.description }]
    }
  },
  apollo: {
    allHomepages: gql`
      {
        allHomepages {
          edges {
            node {
              description
              about
              gallery {
                image
              }
              facilities_text_before
              facilities {
                label
              }
              services {
                image
                title
                text
              }
              establishments {
                label
              }
              dorm_text_before
              rooms {
                capacity
                size
                bed
                price_long_term
                price_short_term
              }
              payment {
                label
              }
              disclaimer {
                label
              }
              house_rules {
                label
              }
              documents_text_before
              documents {
                document {
                  ...on _FileLink {
                    name
                    url
                  }
                }
                label
              }
              documents_text_after
            }
          }
        }
      }
    `
  }
}
</script>
