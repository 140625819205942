<template>
  <h2 :is="is" :class="$style.heading">
    <slot/>
  </h2>
</template>

<script>
export default {
  name: 'Heading',
  props: {
    level: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    is() {
      return `h${this.level}`
    },
  },
}
</script>

<style module lang="scss">
.heading {
  font-size: 2.8em;
  font-weight: 300;
  margin-bottom: 27px;
  margin-top: 27px;
}

h2.heading {
  font-size: 2.8em;
}

h3.heading {
  font-size: 2.4em;
}
</style>
