<template>
  <div id="app" :class="appClass">
    <div ref="map" :class="$style.map"/>
    <div :class="$style.background"/>

    <header :class="$style.header">
      <router-link :is="isRouteHome ? 'h1' : 'router-link'" :class="$style['logo-link']" :to="isRouteHome ? null : '/'">
        <svg viewBox="0 0 122.2 53.6" :class="$style.logo" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 52.6V1.06h4.17V52.6zM36.92 0a26.21 26.21 0 0113.49 3.6 27 27 0 019.74 9.7 25.94 25.94 0 013.6 13.45 26.21 26.21 0 01-3.6 13.49A26.81 26.81 0 0150.41 50a26.21 26.21 0 01-13.49 3.6A25.94 25.94 0 0123.47 50a26.88 26.88 0 01-9.7-9.74 26.21 26.21 0 01-3.6-13.49A26.66 26.66 0 0126.65 2a27 27 0 0110.27-2zm0 4.17a22.47 22.47 0 00-11.29 3 22 22 0 00-8.26 8.3 22.61 22.61 0 1039.22 0 21.94 21.94 0 00-8.26-8.3 22.51 22.51 0 00-11.41-3zM95.37 0a26.21 26.21 0 0113.49 3.6 27 27 0 019.74 9.7 25.94 25.94 0 013.6 13.45 26.21 26.21 0 01-3.6 13.49 26.77 26.77 0 01-46.38 0 26.21 26.21 0 01-3.6-13.49 26.87 26.87 0 017.84-19A26.26 26.26 0 0185.1 2a26.61 26.61 0 0110.27-2zm0 4.17a22.47 22.47 0 00-11.29 3 21.94 21.94 0 00-8.26 8.3A22.61 22.61 0 10118 26.75a22.37 22.37 0 00-3-11.29 21.94 21.94 0 00-8.26-8.3 22.55 22.55 0 00-11.37-2.99z"/></svg>
        <svg viewBox="0 0 200.22 53.62" :class="$style.logo" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.31 1.06a18.32 18.32 0 0116.52 26.45A17.94 17.94 0 0126.6 34a18.65 18.65 0 01-7.27 3.49L32.21 52.6h-5.53L14.25 37.89h-3.87V52.6H6.21V5.23H0l2.54-4.17zm-4.93 32.67h4.93a13.43 13.43 0 0010.31-4.25 14.25 14.25 0 003.94-10A13.82 13.82 0 0022.24 7a14.57 14.57 0 00-6.9-1.74h-4.93zM65.35 0a26.21 26.21 0 0113.49 3.6 26.85 26.85 0 019.7 9.7 25.94 25.94 0 013.6 13.45 26.21 26.21 0 01-3.6 13.49 26.78 26.78 0 01-9.7 9.76 26.79 26.79 0 01-36.7-9.74 26.21 26.21 0 01-3.6-13.49 26.86 26.86 0 017.85-19A26.16 26.16 0 0155.08 2a26.61 26.61 0 0110.27-2zm0 4.17a22.41 22.41 0 00-11.3 3 22 22 0 00-8.26 8.3 22.63 22.63 0 1039.18 0 21.87 21.87 0 00-8.26-8.3 22.5 22.5 0 00-11.36-3zM109.85 24L99.62 1.06h4.54l8 17.81 8-17.81h4.55L114.39 24l12.81 28.6h-4.54L112.12 29l-10.53 23.6H97zM141.62 38.05V52.6h-4.17V38.05h-5.38l2.5-4.17h2.88V13.41a13 13 0 011.82-6.66 13.45 13.45 0 014.92-4.93 13.12 13.12 0 0113.34 0 13.4 13.4 0 014.93 4.93 13 13 0 011.82 6.66V52.6h-4.17V38.05zm0-4.17h18.49V13.41a9 9 0 00-4.77-8.1 9.32 9.32 0 00-4.48-1.14A8.65 8.65 0 00144.19 7a9.24 9.24 0 00-2.57 6.44zM174.3 40.62a10.71 10.71 0 003.94 6.22 10.84 10.84 0 006.82 2.35A10.55 10.55 0 00192.9 46a10.71 10.71 0 003.15-7.77 10.61 10.61 0 00-8.11-10.61L182 26.1a12.61 12.61 0 01-7-4.66 12.87 12.87 0 01-2.69-8 12.61 12.61 0 013.83-9.28 12.63 12.63 0 019.3-3.86A13 13 0 01192 2.08a13.25 13.25 0 014.81 4.74l-3.64 2a8.31 8.31 0 00-7.73-4.4 8.53 8.53 0 00-6.33 2.69 8.92 8.92 0 00-.72 11.89 8.33 8.33 0 004.7 3.1l5.91 1.52a14.55 14.55 0 018.07 5.38 14.77 14.77 0 013.15 9.25A14.47 14.47 0 01195.75 49a14.74 14.74 0 01-10.69 4.4 14.56 14.56 0 01-9.32-3.26 15.16 15.16 0 01-5.38-8.34z"/></svg>
        <svg viewBox="0 0 210.61 53.58" :class="$style.logo" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.54 38.05V52.6H5.38V38.05H0l2.5-4.17h2.88V13.41A12.9 12.9 0 017.2 6.75a13.45 13.45 0 014.92-4.93 13.12 13.12 0 0113.34 0 13.63 13.63 0 014.93 4.93 13 13 0 011.82 6.66V52.6h-4.15V38.05zm0-4.17h18.52V13.41a9 9 0 00-4.78-8.1 9.31 9.31 0 00-4.47-1.14A8.63 8.63 0 0012.12 7a9.25 9.25 0 00-2.58 6.44zM55.72 41.15L70 1.06h4.4L55.72 53.58 37.06 1.06h4.4zM88.55 48.43h19.51v4.17H84.38v-31h-6.32l2.5-4.17h3.79V1.06h23.71v4.17H88.55v12.2h12.35v4.17H88.55zM119.33 15.92V52.6h-4.17V.08l21.38 37.74V1.06h4.16v52.52zM170.5 1.06h4.17v38.12a12.94 12.94 0 01-1.82 6.67 13.52 13.52 0 01-4.92 4.93 13.12 13.12 0 01-13.34 0 13.7 13.7 0 01-4.93-4.93 12.94 12.94 0 01-1.82-6.67V1.06h4.22v38.12a9.23 9.23 0 002.62 6.41 8.61 8.61 0 006.63 2.84 9.25 9.25 0 006.44-2.58 8.61 8.61 0 002.8-6.67zM191.13 48.43h19.48v4.17H187v-31h-6.3l2.51-4.17H187V1.06h23.64v4.17h-19.51v12.2h12.36v4.17h-12.36z"/></svg>
      </router-link>

      <h2 :is="isRouteHome ? 'h2' : 'span'" :class="$style.description" v-show="description">{{ description }}</h2>

      <NoSSR v-if="isRouteHome">
        <span :class="$style.button" @click="isFullMap = !isFullMap">{{
          isFullMap ? "Close full map" : "View full map"
        }}</span>
      </NoSSR>
    </header>

    <div :class="$style.body">
      <main :class="$style.main">
        <!--<Section>-->
        <!--<router-link to="/">Home</router-link>-->
        <!--<router-link to="/about">About</router-link>-->
        <!--</Section>-->

        <router-view/>
      </main>

      <LazyHydrate when-visible>
        <Footer :class="$style.footer"/>
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import LazyHydrate from 'vue-lazy-hydration'
import NoSSR from 'vue-no-ssr'
import { bare, info } from './mapStyles'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Footer,
    LazyHydrate,
    NoSSR,
  },
  data() {
    return {
      isMounted: false,
      isFullMap: false,
      isWindowScrolled: false,
      googleMapsScript: null,
      isMapsInit: false,
    }
  },
  computed: {
    appClass() {
      return [
        this.$style.app,
        {
          [this.$style['--header-is-hero']]: this.isRouteHome,
          [this.$style['--is-full-map']]: this.isFullMap,
          [this.$style['--is-mounted']]: this.isMounted,
          [this.$style['--window-is-scrolled']]: this.isWindowScrolled,
        },
      ]
    },
    description() {
      if (this.isRouteHome) {
        return this.allHomepages?.edges[0].node.description
      }

      return this.$store.state.description
    },
    isRouteHome() {
      return this.$route.path === '/'
    },
  },
  watch: {
    $route(to) {
      if (to.path === '/') {
        this.$store.state.marker?.setMap(this.$store.state.map)
        return
      }
      this.$store.state.marker?.setMap(null)
    },
    isFullMap(val) {
      if (val) {
        this.$store.state.map.setOptions({ styles: info })
        return
      }

      this.$store.state.map.setOptions({ styles: bare })
    }
  },
  methods: {
    loadGoogleMaps() {
      if (document.body.contains(this.googleMapsScript)) {
        return
      }
      this.googleMapsScript = document.createElement('script')
      this.googleMapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBXzUrxMtV4buywSA-_1aiyKcPcQYqgU6E&callback=initMap'
      document.body.appendChild(this.googleMapsScript)
    },

    onWindowMousemove() {
      this.initMap()
    },

    onWindowScroll() {
      this.initMap()

      if (window.scrollY > 0) {
        this.isWindowScrolled = true

        return
      }

      this.isWindowScrolled = false
    },

    initMap() {
      if (!window.google?.maps) {
        window.initMap = this.initMap
        this.loadGoogleMaps()
        return
      }

      if (this.isMapsInit) return
      this.isMapsInit = true

      const markerLatLng = { lat: 7.069113, lng: 125.615049 }

      const map = new window.google.maps.Map(this.$refs.map, {
        center: markerLatLng,
        zoom: 16,
        disableDefaultUI: true,
        options: {
          gestureHandling: 'greedy',
        },
        styles: bare,
      })

      this.$store.commit('setMap', map)

      const marker = new window.google.maps.Marker({
        icon: {
          path: 'M 0,0 -20,-40 20,-40 z',
          fillColor: '#efefef',
          fillOpacity: 1,
          scale: 1,
          strokeColor: 'white',
          strokeWeight: 1,
        },
        position: markerLatLng,
        map: this.isRouteHome ? map : null,
      })

      this.$store.commit('setMarker', marker)

      setTimeout(() => {
        map.panBy(0, 90)
      }, 0)
    }
  },
  mounted() {
    setTimeout(() => {
      this.isMounted = true
    }, 0)

    window.addEventListener('mousemove', this.onWindowMousemove)
    window.addEventListener('scroll', this.onWindowScroll)
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.onWindowMousemove)
    window.removeEventListener('scroll', this.onWindowScroll)
  },

  metaInfo: {
    titleTemplate(title) {
      if (!title) {
        return '100 Roxas Avenue'
      }

      return `${title} | 100 Roxas Avenue`
    },
  },

  apollo: {
    allHomepages: gql`
      {
        allHomepages {
          edges {
            node {
              description
            }
          }
        }
      }
    `,
  },
}
</script>

<style module lang="scss">
// color palette: https://icolorpalette.com/f26a09_98380f_8c6c60_6a5464_d98715

@import url("https://p.typekit.net/p.css?s=1&k=mys3gek&ht=tk&f=10879.10881.10884.10886&a=14960202&app=typekit&e=css");

@font-face {
  font-family: "futura-pt";
  src: url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "futura-pt";
  src: url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "futura-pt";
  src: url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

// @font-face {
//   font-family: "futura-pt";
//   src: url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
//       format("woff2"),
//     url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
//       format("woff"),
//     url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
//       format("opentype");
//   font-display: swap;
//   font-style: normal;
//   font-weight: 700;
// }

$mount-delay: 0s;

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  background-color: #554350;
  height: 100%;
  margin: 0;
}

.app {
  font-family: futura-pt, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  text-align: center;
}

.map {
  height: 100%;
  left: 0;
  position: absolute !important;
  right: 0;
  top: 0;

  > div {
    background-color: transparent !important;
    height: auto !important;
    bottom: -27px;
  }
}

.--header-is-hero .map {
  height: 75%;
  position: relative !important;
  transition: height .25s;

  @media (min-width: 769px) {
    height: 50%;
  }
}

.--is-full-map .map {
  height: 100%;
}

.background {
  background-color: #6a5464;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.--header-is-hero .background {
  height: 75%;
  transition: height .25s;

  @media (min-width: 769px) {
    height: 50%;
  }
}

.header {
  // background-color: #8c6c60;
  left: 0;
  padding-left: 9.648%;
  padding-right: 9.648%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.--header-is-hero .header {
  background-color: transparent;
  min-height: 0;
  top: 37.5%;
  transform: translateY(-45px);
  transition: min-height 0s .25s, top .25s, transform .25s;

  @media (min-width: 769px) {
    top: 25%;
  }
}

.--is-full-map .header {
  min-height: 100%;
  top: 0;
  transform: translateY(0);
  transition: top .25s, transform .25s;
}

.logo-link {
  background-color: transparent;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  text-decoration: none;
}

.--header-is-hero .logo-link {
  transition: background-color;
}

.--is-full-map .logo-link {
  background-color: #8c6c60;
  transition: background-color .25s;
}

.logo {
  // background-color: #000;
  display: inline-block;
  height: 27px;
  padding: 9px 10px;
  vertical-align: top;

  path {
    fill: #fff;
  }
}

.description {
  color: #fff;
  font-size: 1.8em;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 9px;
}

.--header-is-hero .description {
  transition: opacity .25s;
}

.--is-full-map .description {
  opacity: 0;
  transition: opacity 0s;
}

.button {
  background-color: #eae3e0;
  color: #5a463e;
  cursor: pointer;
  font-size: 1.6em;
  margin-top: 27px;
  padding: 6px 15px;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  transition: background-color .25s;
}

.button:hover {
  background-color: #fff;
}

.--is-full-map .button {
  margin-top: -45px;
  right: 20px;
  transform: translateX(0);
}

.body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

.main {
  // background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  float: left;
  width: 100%;
}

.footer {
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
}
</style>
