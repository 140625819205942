var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Section',{class:_vm.$style.dorm,attrs:{"compact":""}},[_c('Heading',[_vm._v("Dorm rates")]),_c('RichText',{class:_vm.$style.text,attrs:{"data":_vm.text_before}}),_c('ul',{class:_vm.$style.rooms},_vm._l((_vm.rooms),function(ref,i){
var bed = ref.bed;
var capacity = ref.capacity;
var price_long_term = ref.price_long_term;
var price_short_term = ref.price_short_term;
var size = ref.size;
return _c('li',{key:i,class:_vm.$style.room},[_c('span',{class:[_vm.$style.block, _vm.$style.capacity]},[_vm._v("Room for "+_vm._s(capacity))]),_c('ul',{class:_vm.$style['room-details']},[_c('li',{class:_vm.$style['room-detail']},[_c('span',{class:[_vm.$style.block, _vm.$style.label]},[_vm._v("Room size")]),_c('span',{class:[_vm.$style.block, _vm.$style.value]},[_vm._v(_vm._s(size))])])]),_c('ul',{class:_vm.$style['room-details']},[_c('li',{class:_vm.$style['room-detail']},[_c('span',{class:[_vm.$style.block, _vm.$style.label]},[_vm._v("Bed type")]),_c('span',{class:[_vm.$style.block, _vm.$style.value]},[_vm._v(_vm._s(bed))])])]),_c('ul',{class:_vm.$style['room-details']},[_c('li',{class:_vm.$style['room-detail']},[_c('span',{class:[_vm.$style.block, _vm.$style.label]},[_vm._v("long term")]),_c('span',{class:[_vm.$style.block, _vm.$style.value]},[_vm._v(_vm._s(price_long_term))])])]),_c('ul',{class:_vm.$style['room-details']},[_c('li',{class:_vm.$style['room-detail']},[_c('span',{class:[_vm.$style.block, _vm.$style.label]},[_vm._v("short term")]),_c('span',{class:[_vm.$style.block, _vm.$style.value]},[_vm._v(_vm._s(price_short_term))])])])])}),0),_c('div',{class:_vm.$style.lists},[_c('div',{class:[_vm.$style['list-container'], _vm.$style.payment]},[_c('Heading',{class:_vm.$style['list-heading'],attrs:{"level":3}},[_vm._v("Payment")]),_c('Ul',{class:_vm.$style.list},_vm._l((_vm.payment),function(ref,i){
var label = ref.label;
return _c('Li',{key:i,class:_vm.$style.li},[_vm._v(_vm._s(label))])}),1)],1),_c('div',{class:[_vm.$style['list-container'], _vm.$style.disclaimer]},[_c('Heading',{class:_vm.$style['list-heading'],attrs:{"level":3}},[_vm._v("Disclaimer")]),_c('Ul',{class:_vm.$style.list},_vm._l((_vm.disclaimer),function(ref,i){
var label = ref.label;
return _c('Li',{key:i,class:_vm.$style.li},[_vm._v(_vm._s(label))])}),1)],1),_c('div',{class:[_vm.$style['list-container'], _vm.$style['house-rules']]},[_c('Heading',{class:_vm.$style['list-heading'],attrs:{"level":3}},[_vm._v("House rules")]),_c('Ul',{class:_vm.$style.list},_vm._l((_vm.house_rules),function(ref,i){
var label = ref.label;
return _c('Li',{key:i,class:_vm.$style.li},[_vm._v(_vm._s(label))])}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }