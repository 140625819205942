// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RichTextElement_p_u_5mO{margin-bottom:27px;margin-top:27px}.RichTextElement_p_u_5mO strong{font-weight:500}", ""]);
// Exports
exports.locals = {
	"p": "RichTextElement_p_u_5mO"
};
module.exports = exports;
