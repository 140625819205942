<template>
  <Section :class="$style.documents" compact>
    <Heading>Documents</Heading>
    
    <RichText :class="$style.text" :data="text_before"/>

    <ul :class="$style.list">
      <li :class="$style.li" v-for="({ document, label }, key) in documents" :key="key">
        <a :class="$style.anchor" :href="document.url" :title="document.name" target="_blank" rel="nofollow">
          <img :class="$style.icon" src="@/assets/filetypes/pdf.svg"/>
          <span :class="$style.label">{{ label }}</span>
        </a>
      </li>
    </ul>

    <RichText :class="$style.text" :data="text_after"/>
  </Section>
</template>

<script>
import Heading from '@/components/Heading.vue'
import RichText from '@/components/RichText.vue'
import Section from '@/components/Section.vue'

export default {
  name: 'Documents',
  components: {
    Heading,
    RichText,
    Section,
  },
  props: {
    data: Object,
  },
  computed: {
    documents() {
      return this.data.documents
    },
    text_before() {
      return this.data.text_before
    },
    text_after() {
      return this.data.text_after
    },
  }
}
</script>

<style module lang="scss">
.documents {
  background-color: #efefef;
}

.text {
  font-size: 1.8em;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.li {
  margin-left: 1px;
  margin-top: 1px;
}

.anchor {
  align-items: center;
  background-color: #fff;
  color: #000;
  display: flex;
  font-size: 1.6em;
  padding: 18px 20px;
  text-decoration: none;
}

.anchor:hover {
  box-shadow: 0 0 18px rgba(0, 0, 0, .05);
  position: relative;
  z-index: 1;
}

.icon {
  height: 28px;
  opacity: .75;
  transition: opacity .25s;
}

.anchor:hover .icon {
  opacity: 1;
}

.label {
  margin-left: 10px;
  opacity: .75;
  text-align: left;
  transition: opacity .25s;
}

.anchor:hover .label {
  opacity: 1;
}
</style>
