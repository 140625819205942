<template>
  <Section type="footer" :class="$style.footer" compact>
    <Heading :class="$style.heading">Contact us</Heading>

    <RichText :class="$style.text" :data="contact.text"/>

    <p :class="$style.p" v-if="typeof officeStatus === 'string'">Office is currently <span :class="officeStatusClass">{{ officeStatus }}</span></p>

    <div :class="$style.lists">
      <ul :class="$style.list" v-if="contact.phoneNumbers.length">
        <li :class="$style.li" v-for="({ label, numbers }, key) in contact.phoneNumbers" :key="key">
          <span :class="[$style.block, $style.label]">{{ label }}</span>
          <span :class="$style.values">
            <a :class="[$style.block, $style.anchor]" v-for="({ href, label }, j) in numbers" :key="j" :href="href" :title="href">{{ label }}</a>
          </span>
        </li>
      </ul>

      <NoSSR>
        <ul :class="$style.list" v-if="contact.email">
            <li :class="$style.li">
              <span :class="[$style.block, $style.label]">Email</span>
              <a :class="[$style.block, $style.anchor]" :href="`mailto:${contact.email}`">{{ contact.email }}</a>
            </li>
        </ul>
      </NoSSR>

      <ul :class="$style.list" v-if="contact.socialMedia.length">
        <li :class="$style.li">
          <span :class="[$style.block, $style.label]">Social media</span>
          <span :class="$style.values">
            <a :class="[$style.block, $style.anchor]" v-for="({ label, link }, key) in contact.socialMedia" :key="key" :href="link.url" :title="link.url" target="_blank" rel="nofollow">{{ label }}</a>
          </span>
        </li>
      </ul>
    </div>

    <small :class="$style.copyright">&copy; {{ year }} LCI Corporation</small>
  </Section>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import PhoneNumber from 'awesome-phonenumber'
import gql from 'graphql-tag'

import Heading from '@/components/Heading.vue'
import RichText from '@/components/RichText.vue'
import Section from '@/components/Section.vue'

const OFFICE_DAYS = [1, 2, 3, 4, 5, 6]
const OFFICE_HOUR_START = 8 * 60
const OFFICE_MINUTE_START = 30
const OFFICE_HOUR_END = 17 * 60
const OFFICE_MINUTE_END = 0

export default {
  name: 'Footer',
  components: {
    Heading,
    NoSSR,
    RichText,
    Section,
  },
  data() {
    return {
      officeStatus: undefined,
    }
  },
  computed: {
    contact() {
      const contact = this.allContacts?.edges[0].node
      const phoneNumbers = {}

      Object.values(contact?.phone_numbers || {}).forEach(({ label, phone_number }) => {
        const phoneNumber = new PhoneNumber(phone_number, 'PH')

        phoneNumbers[label] = phoneNumbers[label] || {
          label,
          numbers: [],
        }
        phoneNumbers[label].numbers.push({
          href: phoneNumber.getNumber('rfc3966'),
          label: phoneNumber.getNumber('national'),
        })
      })

      return {
        email: contact?.email,
        phoneNumbers: Object.values(phoneNumbers),
        socialMedia: contact?.social_media || [],
        text: contact?.text,
      }
    },

    officeStatusClass() {
      return [
        this.$style['office-status'],
        this.$style[`office-status--${this.officeStatus}`],
      ]
    },

    year() {
      const date = new Date()
      return date.getFullYear()
    }
  },
  mounted() {
    this.setOfficeStatus()
  },
  methods: {
    async fetchOfficeDayHourMinute() {
      const response = await fetch('https://www.worldtimeapi.org/api/timezone/Asia/Manila')
      const json = await response.json()
      const date = new Date(json.datetime.split('.')[0])

      return [date.getDay(), date.getHours(), date.getMinutes()]
    },

    async getIsOfficeOpen() {
      const [day, hour, minute] = await this.fetchOfficeDayHourMinute()

      if (!OFFICE_DAYS.includes(day)) return false

      const hourInMinutes = hour * 60

      if (hourInMinutes + minute < OFFICE_HOUR_START + OFFICE_MINUTE_START) return false
      if (hourInMinutes + minute > OFFICE_HOUR_END + OFFICE_MINUTE_END) return false

      return true
    },

    async setOfficeStatus() {
      const isOfficeOpen = await this.getIsOfficeOpen()

      this.officeStatus = isOfficeOpen ? 'open' : 'closed'
    }
  },

  apollo: {
    allContacts: gql`
      {
        allContacts {
          edges {
            node {
              email
              phone_numbers {
                label
                phone_number
              }
              social_media {
                link {
                  ...on _ExternalLink {
                    url
                  }
                }
                label
              }
              text
            }
          }
        }
      }
    `,
  },
}
</script>

<style module lang="scss">
.footer {
  padding-bottom: 54px;
}

.heading {
  color: #fff;
}

.text {
  color: #fff;
  font-size: 1.8em;
}

.p {
  color: #fff;
  font-size: 1.6em;
  margin-bottom: 27px;
  margin-top: 27px;
}

.office-status {
  cursor: default;
  display: inline-block;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
}

.office-status--open {
  background-color: #e0eae3;
  color: #3e5a46;
}

.office-status--closed {
  background-color: #faddd0;
  color: #8b330d;
}

.lists {
  display: inline-block;
}

.list {
  display: block;
  list-style: none;
  margin-bottom: 0;
  margin-top: 18px;
  padding-left: 0;
}

.list:first-child {
  margin-top: 0;
}

.li {
  display: flex;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
}

.block {
  display: inline-block;
  margin-top: 1px;
  padding: 3px 10px;
}

.values {
  display: inline-block;
}

.label {
  border-right: 5px solid #fff;
  color: #554350;
  cursor: default;
  font-size: 1.6em;
  padding-right: 5px;
  position: relative;
}

.label::before {
  background-color: #fff;
  color: #fff;
  content: '0';
  left: 0;
  padding: 3px 10px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.anchor {
  background-color: #d1c6ce;
  color: #554350;
  font-size: 1.8em;
  margin-left: 1px;
  text-decoration: none;
  transition: background-color .25s;
}

.anchor:hover {
  background-color: #fff;
}

.values > .anchor {
  display: block;
}

.copyright {
  color: #d1c6ce;
  display: block;
  font-size: 1.6em;
  margin-top: 27px;
}
</style>
