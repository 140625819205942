// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Facilities_facilities_aL-lm{background-color:#eae3e0}.Facilities_heading_290yQ,.Facilities_text_1CwTG{color:#5a463e}.Facilities_text_1CwTG{font-size:1.8em}.Facilities_list_3k84u{-moz-column-gap:40px;column-gap:40px}@media(min-width:426px){.Facilities_list_3k84u{-moz-column-count:2;column-count:2}}@media(min-width:1025px){.Facilities_list_3k84u{-moz-column-count:3;column-count:3}}.Facilities_li_oo4IG{color:#5a463e;font-size:1.8em}.Facilities_li_oo4IG:first-child{margin-top:0}.Facilities_li_oo4IG:before{background-color:#d5c7c2!important;color:#d5c7c2!important}", ""]);
// Exports
exports.locals = {
	"facilities": "Facilities_facilities_aL-lm",
	"heading": "Facilities_heading_290yQ",
	"text": "Facilities_text_1CwTG",
	"list": "Facilities_list_3k84u",
	"li": "Facilities_li_oo4IG"
};
module.exports = exports;
