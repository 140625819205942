<template>
  <Section :class="$style.about" compact>
    <RichText :class="textClass" :data="data"/>

    <span :class="$style['read-more']" v-if="shouldShowReadMoreButton" @click="onReadMoreClick">Read more</span>

    <LazyHydrate v-if="!isMounted" never>
      <noscript v-html="noscriptHtml"/>
    </LazyHydrate>
  </Section>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import RichText from '@/components/RichText.vue'
import Section from '@/components/Section.vue'

export default {
  name: 'About',
  components: {
    LazyHydrate,
    RichText,
    Section,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      isMounted: false,
      shouldShowMore: false,
    }
  },
  computed: {
    noscriptHtml() {
      return `<style>.${this.$style.text} > * + * {display:block !important}.${this.$style['read-more']}{display:none}</style>`
    },
    shouldShowReadMoreButton() {
      return this.data?.length > 1 && !this.shouldShowMore
    },
    textClass() {
      return [
        this.$style.text,
        {
          [this.$style['text--show-less']]: !this.shouldShowMore,
        },
      ]
    },
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    onReadMoreClick() {
      this.shouldShowMore = true
    },
  },
}
</script>

<style module lang="scss">
.about {
  background-color: #608c82;
}

.text {
  color: #fff;
  font-size: 2em;

  @media (min-width: 769px) {
    column-count: 2;
    column-gap: 40px;
    text-align: left;
  }

  @media (min-width: 1025px) {
    column-count: 3;
  }
}

.text--show-less > * + * {
  @media(max-width: 768px) {
    display: none;
  }
}

.text p:first-child {
  @media (min-width: 769px) {
    margin-top: 0;
  }
}

.read-more {
  background-color: #e0eae8;
  color: #3e5a54;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6em;
  margin-bottom: 27px;
  padding: 6px 20px;
  transition: background-color .25s;

  @media (min-width: 769px) {
    display: none;;
  }
}

.read-more:hover {
  background-color: #fff;
}
</style>
