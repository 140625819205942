// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Ul_ul_3Gtjx{list-style:none;margin-bottom:27px;margin-top:27px;padding-left:0}", ""]);
// Exports
exports.locals = {
	"ul": "Ul_ul_3Gtjx"
};
module.exports = exports;
