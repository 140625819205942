// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._404_not-found_2lD0Y{color:#fff;left:50%;position:absolute;top:45%;transform:translate(-50%,-50%)}._404_heading_20crU{font-size:10em!important;margin-bottom:0!important;margin-top:0!important}._404_text_PjUdY{color:#fff;font-size:2em;margin-bottom:0;margin-top:0}._404_cta_38I16{background-color:#e8e2e6;color:#554350;display:inline-block;font-size:1.6em;font-weight:500;margin-top:27px;padding:18px 30px;text-decoration:none;transition:background-color .25s}._404_cta_38I16:hover{background-color:#fff}", ""]);
// Exports
exports.locals = {
	"not-found": "_404_not-found_2lD0Y",
	"heading": "_404_heading_20crU",
	"text": "_404_text_PjUdY",
	"cta": "_404_cta_38I16"
};
module.exports = exports;
