// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Heading_heading_36ycn{font-weight:300;margin-bottom:27px;margin-top:27px}.Heading_heading_36ycn,h2.Heading_heading_36ycn{font-size:2.8em}h3.Heading_heading_36ycn{font-size:2.4em}", ""]);
// Exports
exports.locals = {
	"heading": "Heading_heading_36ycn"
};
module.exports = exports;
