// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Gallery_gallery_3WDLj{background:#fff;border-bottom:1px solid #fff;border-right:1px solid #fff;list-style:none;overflow:auto;overflow-y:hidden;margin:0 auto;padding-left:0;-ms-scroll-snap-type:x mandatory;scroll-snap-type:x mandatory;white-space:nowrap}.Gallery_item_1iHzp{scroll-snap-align:start;box-sizing:border-box;border-left:1px solid #fff;display:inline-block;margin-top:1px}@media(min-width:769px){.Gallery_image_zzjON{opacity:.5;transition:opacity .25s}}.Gallery_item_1iHzp:hover .Gallery_image_zzjON{opacity:1}", ""]);
// Exports
exports.locals = {
	"gallery": "Gallery_gallery_3WDLj",
	"item": "Gallery_item_1iHzp",
	"image": "Gallery_image_zzjON"
};
module.exports = exports;
