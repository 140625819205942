// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Footer_footer_2Jpsb{padding-bottom:54px}.Footer_heading_3G6hj,.Footer_text_tAHzQ{color:#fff}.Footer_text_tAHzQ{font-size:1.8em}.Footer_p_1SfZG{color:#fff;font-size:1.6em;margin-bottom:27px;margin-top:27px}.Footer_office-status_qUsHg{cursor:default;display:inline-block;font-weight:500;padding-left:5px;padding-right:5px}.Footer_office-status--open_2MCSR{background-color:#e0eae3;color:#3e5a46}.Footer_office-status--closed_3eNn_{background-color:#faddd0;color:#8b330d}.Footer_lists_23iqu{display:inline-block}.Footer_list_9DDdq{display:block;list-style:none;margin-bottom:0;margin-top:18px;padding-left:0}.Footer_list_9DDdq:first-child{margin-top:0}.Footer_li_FyQQ_{display:flex;display:inline-flex;flex-wrap:wrap;justify-content:center;margin-left:5px;margin-right:5px}.Footer_block_1Ye3j{margin-top:1px;padding:3px 10px}.Footer_block_1Ye3j,.Footer_values_2pKJ_{display:inline-block}.Footer_label_20FhL{border-right:5px solid #fff;color:#554350;cursor:default;font-size:1.6em;padding-right:5px;position:relative}.Footer_label_20FhL:before{background-color:#fff;color:#fff;content:\"0\";left:0;padding:3px 10px;position:absolute;right:0;top:0;z-index:-1}.Footer_anchor_28JUL{background-color:#d1c6ce;color:#554350;font-size:1.8em;margin-left:1px;text-decoration:none;transition:background-color .25s}.Footer_anchor_28JUL:hover{background-color:#fff}.Footer_copyright_6Xrdg,.Footer_values_2pKJ_>.Footer_anchor_28JUL{display:block}.Footer_copyright_6Xrdg{color:#d1c6ce;font-size:1.6em;margin-top:27px}", ""]);
// Exports
exports.locals = {
	"footer": "Footer_footer_2Jpsb",
	"heading": "Footer_heading_3G6hj",
	"text": "Footer_text_tAHzQ",
	"p": "Footer_p_1SfZG",
	"office-status": "Footer_office-status_qUsHg",
	"office-status--open": "Footer_office-status--open_2MCSR",
	"office-status--closed": "Footer_office-status--closed_3eNn_",
	"lists": "Footer_lists_23iqu",
	"list": "Footer_list_9DDdq",
	"li": "Footer_li_FyQQ_",
	"block": "Footer_block_1Ye3j",
	"values": "Footer_values_2pKJ_",
	"label": "Footer_label_20FhL",
	"anchor": "Footer_anchor_28JUL",
	"copyright": "Footer_copyright_6Xrdg"
};
module.exports = exports;
