<template>
  <p :class="$style.p" v-if="data.type === 'paragraph'">
    <RichTextNode v-for="(node, i) in nodes" :key="i" :data="node"/>
  </p>
</template>

<script>
import RichTextNode from '@/components/RichTextNode.vue'

export default {
  name: 'RichTextElement',
  components: {
    RichTextNode,
  },
  props: {
    data: Object,
  },
  computed: {
    nodes() {
      const spans = this.data.spans
      const text = this.data.text

      if (!spans.length) {
        return [{ text }]
      }

      const nodes = []

      let prevEnd = spans[0].start

      spans.forEach(({ data, end, start, type }) => {
        if (prevEnd < start) {
          nodes.push({ text: text.slice(prevEnd, start) })
        }

        nodes.push({
          data,
          text: text.slice(start, end),
          type,
        })

        prevEnd = end
      })

      return [
        { text: text.slice(0, spans[0].start) },
        ...nodes,
        { text: text.slice(spans[spans.length - 1].end, text.length) },
      ]
    },
  },
}
</script>

<style module lang="scss">
.p {
  margin-bottom: 27px;
  margin-top: 27px;
}

.p strong {
  font-weight: 500;
}
</style>
